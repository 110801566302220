// import bnb from ".images/bnbclone-img.png";

const ProjectArray = [
  {
    key: "01",
    img: "./images/bnbclone-img.png",
    title: "AirBnb Clone",
    tech: "React, Javascript, Css, Netlifly",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    lanugages: "React, CSS ",
    link: "https://wonderful-engelbart-a0c508.netlify.app/",
  },
  {
    key: "02",
    img: "./images/hulu-clone-pic.png",
    title: "Hulu Clone",
    tech: "React, Javascript, Css, Netlifly",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    lanugages: " React, CSS",
    link: "https://olukukoyi.github.io/HuluClone/",
  },
  {
    key: "03",
    img: "./images/tindog-img.png",
    title: "TinDog",
    tech: "HTML, CSSt",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    lanugages: " React, CSS",
    link: "https://olukukoyi.github.io/Tindog/",
  },
  {
    key: "04",
    img: "./images/nycfood-img2.png",
    title: "NYC Trending Food Locations",
    tech: "React, Javascript, Css, Netlifly",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    lanugages: " React, CSS",
    link: "https://nyc-food.vercel.app/",
  },
  // {
  //   key: "05",
  //   img: "asdasd",
  //   title: "OakBook, my personal app",
  //   info: "Underconstruction",
  //   lanugages: " React, CSS",
  //   link: "https://wonderful-engelbart-a0c508.netlify.app/",
  // },
];

export default ProjectArray;
